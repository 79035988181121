import { PageProps } from "gatsby";
import React from "react";

import { ClairnoteDnClairnoteSn } from "../../components/pages/ClairnoteDnClairnoteSn";

import { dnProps } from "../../js/utils";

const ClairnoteDnClairnoteSnPage = (props: PageProps) => (
  <ClairnoteDnClairnoteSn {...{ ...dnProps, ...props }} />
);

export default ClairnoteDnClairnoteSnPage;
